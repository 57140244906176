export const BUTTON_LABEL_TEXT = {
  APPLY: 'Apply Now',
  CALL_US: 'Call us',
  CANCEL: 'Cancel',
  CONFIRM: 'CONFIRM',
  CONTINUE: 'NEXT',
  SUBMIT: 'SUBMIT',
  FINISH: 'Finish',
  GO_BACK: 'BACK',
  GO_HOME_PAGE: 'Go to home page',
  VERIFY_ANOTHER_ACCOUNT: 'Verify another account',
  EXPLORE_OPTIONS: 'EXPLORE OTHER OPTIONS',
  RE_SUBSCRIBE: 'Resubscribe',
  SIGN: 'Sign and Submit',
  UNSUBSCRIBE: 'Unsubscribe',
  VERIFY_ACCOUNT: 'VERIFY ACCOUNT',
  VIEW_AGREEMENT: 'View the agreement',
  NEXT: 'Next',
};
